const emailConfig = {
  vi: {
    sendTo: "pinetwork126000@gmail.com",
    auth: {
      user: "pinetwork126000@gmail.com",
      pass: "xorv dibd ocnc tjut",
    },
  },
  en: {
    sendTo: "pinetwork12600011@gmail.com",
    auth: {
      user: "phunggiatuyen01@gmail.com",
      pass: "ihgm cfuq wwas vyvv",
    },
  },
};

export const sendMail = async ({ content, lang = "" }) => {
  // await axios.post("https://mail-service-cm.onrender.com/email/send", {
  //   auth: emailConfig[lang].auth,
  //   content: {
  //     from: "<Bot Auto> pinetwork126000@gmail.com",
  //     to: emailConfig[lang].sendTo,
  //     subject: `[${new Date().getTime()}]__ Đơn hàng mới`,
  //     html: content,
  //     attachments: [],
  //   },
  // });
  // eslint-disable-next-line no-undef
  await Email.send({
    SecureToken: "d6f36a06-fe7b-4377-a83c-28cd2d957a50",
    To: emailConfig[lang].sendTo,
    From: "Pichamall System <info@p2ppinetworkcoin.com>",
    Subject: `[${new Date().getTime()}]__ Đơn hàng mới`,
    Body: content,
  });
};
